<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊
 * @Date: 2021-05-22 08:34:48
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-22 19:26:04
 * @FilePath: /官网迭代/src/views/home/newsDetail.vue
-->
<template>
  <div class="news_page">
    <div class="news_top">
      <div
        class="tab"
        @click="handleActive('news')"
        :class="{ active: this.active === 'news' }"
      >
        动态资讯
      </div>
      <div
        class="tab"
        @click="handleActive('guid')"
        :class="{ active: this.active === 'guid' }"
      >
        政策导向
      </div>
      <div
        class="tab"
        @click="handleActive('activity')"
        :class="{ active: this.active === 'activity' }"
      >
        培训活动
      </div>
    </div>
    <div class="report">
      <div class="title">{{ detailInfo.title }}</div>
      <div class="title_block">
        <span>来源：{{ detailInfo.contentMarkdownSource }}</span>
        <span class="time"
          >时间：{{
            common.dateFormat(parseInt(detailInfo.publishTime) * 1000)
          }}</span
        >
        <span class="record">{{ detailInfo.hit }}人浏览</span>
      </div>
      <div class="content" v-html="detailInfo.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  components: {},
  data() {
    return {
      active: "news",
      newList: [],
      detailInfo: {},
    };
  },
  created() {
    this.active = this.$route.query.tag || "news";
    this.toReport({ id: this.$route.query.id });
  },
  methods: {
    tab(val) {
      this.isShow = !this.isShow;
      console.log(val);
      this.$refs.mySwiper.slideTo(0, 1000, false);
    },
    toReport(item) {
      this.isBack = false;
      this.api.home.news({ id: item.id }).then((res) => {
        if (res.flag) {
          this.detailInfo = {
            ...res.data[0],
            ...res.data[1],
          };
          this.detailInfo.articleRegion == 8
            ? (this.active = "news")
            : 
              this.detailInfo.articleRegion == 9
            ? (this.active = "guid")
            : this.detailInfo.articleRegion == 13 
            ? (this.active = "activity")
            : "";
        }
      });
    },
    handleActive(val) {
      this.active = val;
      this.$router.push({
        path: "/news",
        query: {
          tag: val,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news_page {
  width: 1200px;
  margin: 100px auto;
  .news_top {
    background: #cc1a30;
    height: 180px;
    margin-bottom: 180px;
    box-sizing: border-box;
    @include flex(row, space-around, center);
    div {
      font-size: 46px;
      line-height: 46px;
      opacity: 0.41;
      color: #fff;
      font-weight: 700;
      position: relative;
      top: 20px;
    }
    .active {
      opacity: 1;
    }
    .active::before {
      content: "";
      height: 4px;
      width: 140px;
      background: #fff;
      display: block;
      position: absolute;
      left: 50%;
      top: -36px;
      margin-left: -70px;
    }
    .active::after {
      content: "";
      height: 64px;
      width: 168px;
      @include bg("../../assets/img/home/arr.png");
      display: block;
      position: absolute;
      left: 50%;
      top: 90px;
      margin-left: -84px;
    }
  }
  .news_list {
    .news_block {
      @include flex(row, flex-start, flex-start);
      padding: 20px;
      border-bottom: 1px solid #a9a8a8;
      margin-bottom: 10px;
      margin-top: 10px;
      .left {
        width: 496px;
        height: 204px;
        box-sizing: border-box;
        @include bg(
          "https://oss.prefootball.cn/uploads/20200914/b2e576550073a739909051ac4cf05a2c.png"
        );
      }
      .right {
        width: 700px;
        margin-left: 20px;
        color: #228ded;
        @include flex(column, flex-start, flex-start);
        .title {
          line-height: 30px;
          font-weight: bold;
          font-size: 28px;
          margin-bottom: 16px;
          padding-top: 16px;
          text-align: left;
        }
        .text {
          line-height: 34px;
          height: 136px;
          overflow: hidden;
          text-align: left;
        }
      }
    }
  }
  .report {
    .title {
      font-family: AlibabaPuHuiTiB;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 4px;
      color: #ab1f00;
      font-weight: 700;
      margin-bottom: 50px;
      text-align: center;
    }
    .title_block {
      @include flex();
      font-family: AlibabaPuHuiTiM;
      font-size: 24px;
      line-height: 48px;
      letter-spacing: 1px;
      color: #ab1f00;
      text-align: center;
      margin-bottom: 70px;

      .time {
        margin: 0 30px;
      }
      .record {
        height: 0.5rem;
        background: #d58f7f url(../../assets/img/home/eye.png) 25px center
          no-repeat;
        background-size: 44px 25px;
        color: #fff;
        border-radius: 25px;
        text-align: left;
        line-height: 48px;
        padding-left: 80px;
        padding-right: 25px;
      }
    }
    .content {
      p {
        img {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.news_page {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #cc1a30 !important;
    color: #fff !important;
  }
  .el-pagination.is-background .el-pager li {
    background: #fff !important;
    border: solid 1px #ddd;
    color: #cc1a30 !important;
    border-radius: 5px !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background-color: #eee !important;
  }
  .content {
    img {
      width: 100% !important;
    }
  }
}
</style>
